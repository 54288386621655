export default function IconcDPBag() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M24.6375 5.52224C22.2878 4.57642 13.4165 5.12814 8.37071 5.52224C3.81639 5.81789 6.78895 7 9.27419 7C11.7594 7 27.5746 6.70451 24.6375 5.52224Z"
                stroke="black"
                strokeWidth="0.88"
                strokeLinecap="round"
            />
            <path
                d="M6.34991 6.29756C5.89986 7.19766 4.99976 10.123 4.99976 14.6235C4.99976 19.124 5.14977 23.5495 5.22478 25.1997C5.89986 25.7248 8.59816 26.8604 13.9988 26.5003L13.9998 26.5001L15.3509 25.8748L25.252 23.6245C25.7771 24.2246 27.0072 24.8847 27.7273 22.7244C28.6274 20.0241 26.8272 19.7991 25.9271 19.7991C26.1521 19.274 26.4221 17.9989 25.7021 17.0988C25.627 15.3736 25.477 11.7432 25.477 11.0231C25.552 9.82296 25.342 6.62011 24.802 5.90002"
                stroke="black"
                strokeWidth="0.88"
                strokeLinecap="round"
            />
            <path d="M25.9271 19.7991C24.877 20.1741 23.3168 21.5093 25.4771 23.8495" stroke="black" strokeWidth="0.88" strokeLinecap="round" />
            <path
                d="M5.22465 10.7981C5.14964 11.3232 5.49468 12.4633 7.4749 12.8233C9.95017 13.2734 12.8754 11.4732 14.2256 11.4732C15.3057 11.4732 22.1764 11.1731 25.4768 11.0231"
                stroke="black"
                strokeWidth="0.88"
                strokeLinecap="round"
            />
            <path d="M14 11.9999V26.4999" stroke="black" strokeWidth="0.88" strokeLinecap="round" />
            <path d="M6.7998 17.7738L10.8503 17.9988" stroke="black" strokeWidth="0.88" strokeLinecap="round" />
            <path d="M6.7998 22.2744L10.8503 22.4994" stroke="black" strokeWidth="0.88" strokeLinecap="round" />
            <path d="M25.477 16.8738C23.3768 17.4738 19.8964 19.7541 22.7767 24.0746" stroke="black" strokeWidth="0.88" strokeLinecap="round" />
            <rect x="0.44" y="0.44" width="31.12" height="31.12" rx="3.56" stroke="black" strokeWidth="0.88" />
        </svg>
    );
}
